import React, { useState } from "react";
import Button from "@mui/material/Button";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Software } from "../../types";
import { useSelector, useDispatch } from "react-redux";

import { setSoftwares } from "../Redux/MainStore";
import {
  convertToArray,
  convertToList,
} from "../../utils/convertDataStructures";
import { GridColumns } from "@mui/x-data-grid";

export const SoftwareControlLogic = () => {
  const Dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const softwares = useSelector((state: any) => state.mainData.softwares);
  const converted = convertToArray(softwares);

  const [open, setOpen] = React.useState(false);

  const [dialogSerialnumber, setDialogSerialnumber] = React.useState();
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const [searchText, setSearchText] = React.useState("");

  const getDownloadLink = ({
    model,
    version,
  }: {
    model: string;
    version: string;
  }) => {
    const apiName = "ThermonovaAPI";
    const path = "/presignedurl";

    API.get(apiName, path, {
      queryStringParameters: {
        SwModel: model,
        SwVersion: version,
      },
    })
      .then((response) => {
        window.location.href = response;
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  function createData({ SoftwareModel, SoftwareVersion, id }: any) {
    return {
      id: id,
      col1: SoftwareModel,
      col2: SoftwareVersion,
      col3: "",
    };
  }

  React.useEffect(() => {
    getData();
    const interval = setInterval(() => {
      getData();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getSoftwares(): Promise<[]> {
    const apiName = "ThermonovaAPI";
    const path = "/software";

    return new Promise((resolve, reject) => {
      API.get(apiName, path, {})
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          // console.log(error.response);
          reject(error);
        });
    });
  }
  type Response = {
    SoftwareModel: string;
    SoftwareVersion: string;
    data: object;
  };

  function getData() {
    setIsLoading(true);
    getSoftwares().then((response: Response[]) => {
      setIsLoading(false);
      const d: Software[] = response.map((n) => {
        const { SoftwareModel, SoftwareVersion } = n;
        return {
          SoftwareModel,
          SoftwareVersion,
          id: SoftwareVersion.toString() + SoftwareModel.toString(),
        };
      });

      Dispatch(setSoftwares(convertToList(d)));
    });
  }

  const rows = () => {
    const d = converted.map((element) => {
      return createData(element);
    });

    const searchRegex = new RegExp(escapeRegExp(searchText), "i");
    const filteredRows = d.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return searchRegex.test(row[field].toString());
      });
    });

    return filteredRows;
  };

  const columns: GridColumns = [
    { field: "col1", headerName: "Software Model", width: 100 },
    { field: "col2", headerName: "Software Version", width: 150 },
    {
      field: "col3",
      headerName: "Actions",
      width: 170,
      align: "right",
      renderCell: (cellValues) => {
        return (
          <Button
            size="small"
            variant="outlined"
            value={cellValues.row.col3}
            onClick={(event) =>
              getDownloadLink({
                model: cellValues.row.col1,
                version: cellValues.row.col2,
              })
            }
          >
            Download zip file
          </Button>
        );
      },
    },
  ];

  function escapeRegExp(value: string) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
  };

  function QuickSearchToolbar(props: any) {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <TextField
          autoFocus
          variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder="Search…"
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? "visible" : "hidden" }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          sx={{
            width: {
              xs: 1,
              sm: "auto",
            },
            m: (theme) => theme.spacing(1, 0.5, 1.5),
            "& .MuiSvgIcon-root": {
              mr: 0.5,
            },
            "& .MuiInput-underline:before": {
              borderBottom: 1,
              borderColor: "divider",
            },
          }}
        />
      </Box>
    );
  }
  QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  };

  return {
    rows: rows(),
    columns,
    open,
    handleClose,
    dialogSerialnumber,
    setDialogSerialnumber,
    QuickSearchToolbar,
    searchText,
    requestSearch,
    handleOpen,
    isLoading,
  };
};
