import React from "react";
import { API } from "aws-amplify";
import Alert from "@mui/material/Alert";
import xml2json from "xml2js";
import JSZip from "jszip";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addSoftware } from "../../../reducers/gatewayReducer";

type FileContentJSON = {
  softwareModel: string;
  softwareVersion: string;
  data: any;
  error: any;
};
export const AddSoftwareLogic = () => {
  const [fileContentJSON, setFileContentJSON] =
    React.useState<FileContentJSON>();
  const [APIalarm, setAPIalarm] = React.useState<number | string>("");
  const [disabledSendButton, setDisabledSendButton] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [zipFile, setZipFile] = React.useState<Blob | string>("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [files, setFiles] = React.useState<File[] | null>(null);

  const Dispatch = useDispatch();

  React.useEffect(() => {
    if (zipFile) {
      setDisabledSendButton(false);
      setLoading2(false);
    }
  }, [zipFile]);

  const handleSend = () => {
    const apiName = "ThermonovaAPI";
    const path = "/software";

    setLoading(true);
    API.put(apiName, path, {
      body: { data: fileContentJSON },
    })
      .then((response) => {
        const {
          softwareModel: SoftwareModel,
          softwareVersion: SoftwareVersion,
        } = fileContentJSON!;
        Dispatch(
          addSoftware({
            SoftwareModel,
            SoftwareVersion,
            id: SoftwareVersion.toString() + SoftwareModel.toString(),
          })
        );

        uploadToS3(response);
      })
      .catch((err) => {
        // console.log(err);
        if (err.response?.status === 400) {
          setErrorMessage(err.response?.data);
          setAPIalarm(4);
        }
        setLoading(false);
      });
  };

  const uploadToS3 = (data: any) => {
    const config: any = { "Content-type": "multipart/form-data" };
    let form = new FormData();

    for (var key in data.fields) {
      form.append(key, data.fields[key]);
    }

    try {
      form.append("file", zipFile);
    } catch (error) {
      console.log("Error Creating Data", error);
    }

    axios
      .post(data.url, form, config)
      .then((resp) => {
        setAPIalarm(2);
        setLoading(false);
        handleClear();
      })
      .catch((err) => {
        setAPIalarm("");
        handleClear();
      });
  };

  const handleClear = (clearAlarm = false) => {
    setZipFile("");
    setFiles(null);
    setFileContentJSON(undefined);
    if (clearAlarm) setAPIalarm("");

    setLoading(false);
    setDisabledSendButton(true);
  };
  const readFile = (file: File) => {
    let reader = new FileReader();
    reader.onload = () => convertToJSON(reader.result?.toString());
    reader.readAsText(file);
  };

  const zipFiles = (files: File[]) => {
    setLoading2(true);
    const zip = new JSZip();

    for (let file = 0; file < files.length; file++) {
      zip.file(files[file].webkitRelativePath, files[file]);
    }

    zip.generateAsync({ type: "blob" }).then(function (blob) {
      setZipFile(blob);
    });
  };

  const convertToJSON = (xmlString: string | undefined) => {
    if (!xmlString) return;

    xml2json.parseString(xmlString, function (err: any, result: any) {
      if (err) {
        setAPIalarm(3);
      } else {
        const version = result.MCXShapeData?.AppVersion?.[0];
        const softwareModel = version?.split(".")?.[0];
        const softwareVersion = version?.split(".")?.[1];
        const data: any = {};

        let list1 = [];
        let list2 = [];

        if (softwareModel === "2") {
          const MenuID94: any =
            result.MCXShapeData?.MenuList[0]?.MenuElement?.find(
              (item) => item?.MenuID?.[0] === "94"
            );
          const SubItemsOrder: string[] = MenuID94.SubItemsOrder?.[0]
            ?.split(";")
            .map((i) => i.split("Menu_Label_")[1])
            .filter((i) => i !== undefined);

          SubItemsOrder?.forEach((id: string) => {
            const MenuIDX: any =
              result.MCXShapeData?.MenuList[0]?.MenuElement?.find(
                (item) => item?.MenuID?.[0] === id
              );
            const SubItemsOrderX = MenuIDX?.SubItemsOrder?.[0]?.split(";");

            if (SubItemsOrderX) {
              list1 = [...list1, ...SubItemsOrderX];
            }
          });

          // do the same for 95

          const MenuID95: any =
            result.MCXShapeData?.MenuList[0]?.MenuElement?.find(
              (item) => item?.MenuID?.[0] === "95"
            );
          const SubItemsOrder95: string[] = MenuID95.SubItemsOrder?.[0]
            ?.split(";")
            .map((i) => i.split("Menu_Label_")[1])
            .filter((i) => i !== undefined);

          SubItemsOrder95.forEach((id: string) => {
            const MenuIDX: any =
              result.MCXShapeData?.MenuList[0]?.MenuElement?.find(
                (item) => item?.MenuID?.[0] === id
              );
            const SubItemsOrderX = MenuIDX?.SubItemsOrder?.[0]?.split(";");

            if (SubItemsOrderX) {
              list2 = [...list2, ...SubItemsOrderX];
            }
          });
        }

        result?.MCXShapeData?.ParamList[0]?.Parameter?.forEach((item: any) => {
          const enumParam: any = {};
          item?.TextValues?.[0]?.split(";").forEach((v: any) => {
            const key = v?.split("=")[0];
            const val = v?.split("=")[1];

            enumParam[key] = val;
          });

          const nameLanguage: any = {};
          item?.LanguageDescriptionList?.[0]?.LanguageDescription?.forEach(
            (v: any) => {
              nameLanguage[v.LanguageID?.[0]] = v.Description?.[0] || "";
            }
          );

          const enumLanguage: any = {};

          item?.LanguageTextValueList?.[0]?.LanguageDescription?.forEach(
            (v: any) => {
              const obj: any = {};
              v?.Description?.[0].split(";").forEach((a: any) => {
                const key = a.split("=")[0];
                const val = a.split("=")[1];
                obj[key] = val;
              });
              enumLanguage[v.LanguageID?.[0]] = obj;
            }
          );

          var Modulnumber;
          const StartofName = item?.Description?.[0].substring(0, 3);
          if (softwareModel === "1") {
            Modulnumber = "MCX";
          } else if (softwareModel === "2") {
            if (list1.includes(item?.VariableName?.[0])) {
              Modulnumber = "1";
            } else if (list2.includes(item?.VariableName?.[0])) {
              Modulnumber = "2";
            } else if (StartofName === "M1 ") {
              Modulnumber = "1";
            } else if (StartofName === "M2 ") {
              Modulnumber = "2";
            } else {
              Modulnumber = "MCX";
            }
          } else {
            Modulnumber = "MCX";
          }

          data[item.ModbusIndex] = {
            Bit: item?.VarType?.[0],
            Dec: parseInt(item?.Decimals?.[0]),
            Default: parseInt(item?.DefaultValue?.[0]),
            enum: enumParam,
            Max: parseInt(item?.Max?.[0], 10) || 0,
            Min: parseInt(item?.Min?.[0], 10) || 0,
            Modulnumber: Modulnumber,
            Name: item?.Description?.[0],
            ReadWrite: item?.RW?.[0] === "0" ? "rw" : "r",
            Unit: item?.Unit?.[0],
            nameLanguage,
            enumLanguage,
          };
        });

        const error: any = {};

        if (softwareModel !== "2") {
          result.MCXShapeData?.AlarmList[0]?.Alarm?.forEach((item: any) => {
            const nameLanguage: any = {};
            item?.LanguageDescriptionList?.[0]?.LanguageDescription?.forEach(
              (v: any) => {
                nameLanguage[v.LanguageID?.[0]] = v.Description?.[0] || "";
              }
            );

            const i = item.ModbusIndex?.[0] + "." + item.VarMask?.[0];
            error[i] = {
              Warning: Boolean(item?.WarningRele?.[0]),
              Alarm: Boolean(item?.AlarmRele?.[0]),
              ActiveWithUnitOff: Boolean(item?.ActiveWithUnitOff?.[0]),
              StartupDelay: parseInt(item?.StartupDelay?.[0], 10) || 0,
              SteadyDelay: parseInt(item?.SteadyDelay?.[0], 10) || 0,
              SemiAutoPeriod: parseInt(item?.SemiAutoPeriod?.[0], 10) || 0,
              ResetType: parseInt(item?.ResetType?.[0], 10) || 0,
              AlarmEnabled: parseInt(item?.AlarmEnabled?.[0], 10) || 0,
              Name: item?.Description?.[0],
              Label: item?.Label?.[0],
              nameLanguage,
            };
          });
        }

        const output = {
          softwareModel,
          softwareVersion,
          data,
          error,
        };

        setFileContentJSON(output);
      }
    });
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // setLoading(true);
    const files = Array.from(e.target.files!);
    setFiles(files);

    if (files.length === 0) {
      setAPIalarm(5);
      // setLoading(false);
    } else {
      // get .mcxs file and convert to json
      const xmlFile = files.find((file) => file.name.includes(".mcxs"));

      if (xmlFile) {
        zipFiles(files);
        readFile(xmlFile);
      }

      // zip the whole folder
    }
  };

  const returnAlert = () => {
    switch (APIalarm) {
      case 1:
        return <Alert severity="error">Software exists.</Alert>;
      case 2:
        return <Alert severity="success">Software added.</Alert>;
      case 3:
        return (
          <Alert severity="error">Something went wrong. Please try again</Alert>
        );
      case 4:
        return <Alert severity="error">{errorMessage}</Alert>;
      case 5:
        return <Alert severity="error">Empty file</Alert>;
      default:
        return "";
    }
  };

  const triggerFileInput = () => {
    document.getElementById("files")?.click();
  };

  return {
    handleSend,
    returnAlert,
    handleChange,
    files,
    handleClear,
    disabledSendButton,
    setDisabledSendButton,
    setLoading,
    loading,
    loading2,
    triggerFileInput,
  };
};
