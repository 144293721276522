 import { useDispatch, useSelector } from "react-redux";
import { setNavOpenHotspots, setNavOpenSettings } from "../Redux/HP";
export default function NavButtonLogic() {
  const { HPData, NavOpenSettings, NavOpenHotspots } = useSelector(
    (state : any) => state.heatpump
  );
  const dispatch = useDispatch();

  let settingList: string[] = [];
  let HotspotList: string[] = [];

  switch (HPData.Model) {
    case "Nova 440":
      settingList = ["Master", "Modul 1", "Modul 2", "Modul 3", "Modul 4"];
      HotspotList = ["Master", "Modul 1", "Modul 2", "Modul 3", "Modul 4"];
      break;
    case "Nova 330":
      settingList = ["Master", "Modul 1", "Modul 2", "Modul 3", "Modul 4"];
      HotspotList = ["Master", "Modul 1", "Modul 2", "Modul 3"];

      break;
    case "Nova 220":
      settingList = ["Master", "Modul 1", "Modul 2"];
      HotspotList = ["Master", "Modul 1", "Modul 2"];

      break;
    case "Nova 110":
      settingList = ["Master", "Modul 1", "Modul 2"];
      HotspotList = ["Master", "Modul 1"];

      break;
    case "Nature Energy":
      settingList = ["Master", "Modul 1", "Modul 2", "Modul 3", "Modul 4"];
      HotspotList = ["Master", "Modul 1", "Modul 2", "Modul 3", "Modul 4"];

      break;
    

    default:
      settingList = ["Master"];
      HotspotList = ["Master"];
      break;
  }

  return {
    settingList,
    HotspotList,
    setNavOpenHotspots,
    setNavOpenSettings,
    NavOpenSettings,
    NavOpenHotspots,
    dispatch,
  };
}
