import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate, useSearchParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useSWRConfig } from "swr";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Autocomplete from "@mui/material/Autocomplete";
import useSWR from "swr";
import { API } from "aws-amplify";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  useFormContext,
  Controller,
} from "react-hook-form";
import {
  Chip,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";

type Inputs = {
  name: string;
  description: string;
  defaultActive: boolean;
  rules: {
    // SoftwareModel -> SoftwareVersion -> Rule
    [key: string]: {
      [key: string]: {
        [key: string]: boolean;
      };
    };
  };
};

type RuleOption = {
  label: string;
  value: string;
  ability: string;
};

function not(a: readonly RuleOption[], b: readonly RuleOption[]) {
  return a.filter((value) => !b.some((v) => v.value === value.value));
}

function intersection(a: readonly RuleOption[], b: readonly RuleOption[]) {
  return a.filter((value) => b.some((v) => v.value === value.value));
}

function union(a: readonly RuleOption[], b: readonly RuleOption[]) {
  return [...a, ...not(b, a)];
}

const softwareFetcher = () => {
  const apiName = "ThermonovaAPI";
  const path = "/software";
  return API.get(apiName, path, {
    // queryStringParameters: {
    //   fieldsToSelect: "SoftwareModel,SoftwareVersion,pk, sk",
    // },
  });
};

const useSoftwareModelsAndVersions = () => {
  const [activeSoftwareModel, setActiveSoftwareModel] = React.useState("");
  const [activeSoftwareVersion, setActiveSoftwareVersion] = React.useState("");
  const { data, error, isLoading } = useSWR("software", softwareFetcher);

  const { softwareModels } = React.useMemo(() => {
    const out = [];

    if (!data) {
      return {
        softwareModels: [],
      };
    }
    for (const software of data) {
      const { SoftwareModel, SoftwareVersion, data: svData } = software;

      const svDataKeys = svData ? Object.keys(svData) : [];
      const svDataOut: RuleOption[] = [];
      for (const svDataKey of svDataKeys) {
        const svDataValue = svData[svDataKey];
        svDataOut.push({
          label: svDataValue.Name,
          value: svDataKey,
          ability: svDataValue.ReadWrite,
        });
      }
      const model = out.find((m) => m.value === SoftwareModel);
      if (!model) {
        out.push({
          label: `Software Model - ${SoftwareModel}`,
          value: SoftwareModel,
          versions: [
            {
              label: `Software Version - ${SoftwareVersion}`,
              value: SoftwareVersion,
              data: svDataOut,
            },
          ],
        });
      } else {
        model.versions.push({
          label: `Software Version - ${SoftwareVersion}`,
          value: SoftwareVersion,
          data: svDataOut,
        });
      }
    }
    const outSortedByModel = out.sort((a, b) => a.value - b.value);

    return {
      softwareModels: outSortedByModel,
    };
  }, [data]);

  const softwareVersions = React.useMemo(() => {
    if (!activeSoftwareModel) {
      return [];
    }
    const model = softwareModels.find((m) => m.value === activeSoftwareModel);
    if (!model) {
      return [];
    }
    return model.versions;
  }, [softwareModels, activeSoftwareModel]);

  const rules = React.useMemo(() => {
    if (!activeSoftwareModel || !activeSoftwareVersion) {
      return [];
    }
    const model = softwareModels.find((m) => m.value === activeSoftwareModel);
    if (!model) {
      return [];
    }
    const version = model.versions.find(
      (v) => v.value === activeSoftwareVersion
    );
    if (!version) {
      return [];
    }
    return version.data;
  }, [softwareModels, activeSoftwareModel, activeSoftwareVersion]);

  return {
    softwareModels,
    softwareVersions,
    isLoading,
    error,
    activeSoftwareModel,
    setActiveSoftwareModel,
    activeSoftwareVersion,
    setActiveSoftwareVersion,
    rules,
  };
};

export function SoftwareModelAndVersion(props) {
  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          marginTop: "2px",
        }}
      >
        <Grid item xs={6}>
          <Autocomplete
            disablePortal
            size="small"
            id="software-model"
            options={props.softwareModels}
            loading={props.isLoading}
            value={props.activeSoftwareModel}
            isOptionEqualToValue={(option, value) => {
              return option.value === value;
            }}
            onChange={(e, sfModel) => {
              props.setActiveSoftwareModel(sfModel?.value);
            }}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                {...params}
                label="Software Model"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            disablePortal
            size="small"
            id="software-version"
            options={props.softwareVersions}
            loading={props.isLoading}
            value={props.activeSoftwareVersion}
            isOptionEqualToValue={(option, value) => {
              return option.value === value;
            }}
            onChange={(_, sfVersion) => {
              if (sfVersion) {
                props.setActiveSoftwareVersion(sfVersion.value);
              }
            }}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                {...params}
                label="Software Version"
              />
            )}
          />
        </Grid>
      </Grid>
      {props.error && <div>Failed to load software versions</div>}
    </>
  );
}

const templateFetcher = (id) => {
  const apiName = "ThermonovaAPI";
  const path = "/role/get";
  return API.get(apiName, path, {
    queryStringParameters: { id },
  });
};

export default function AddManageRule() {
  const [searchParams] = useSearchParams();
  const templateId = searchParams.get("id");

  const { mutate } = useSWRConfig();
  const [busy, setBusy] = React.useState(false);
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const navigate = useNavigate();
  const methods = useForm<Inputs>({
    defaultValues: React.useMemo(() => {
      return {};
    }, []),
  });

  const {
    data: template,
    error: errorFetchingTemplate,
    isLoading: isLoadingTemplate,
  } = useSWR(templateId, templateFetcher);

  React.useEffect(() => {
    if (template) {
      const { name, description, rules, defaultActive } = template;
      methods.reset({
        name,
        description,
        rules,
        defaultActive,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  const handleClose = () => {
    navigate("/role-management/index");
  };

  const {
    softwareModels,
    softwareVersions,
    isLoading,
    error: softwareError,
    activeSoftwareModel,
    setActiveSoftwareModel,
    activeSoftwareVersion,
    setActiveSoftwareVersion,
    rules,
  } = useSoftwareModelsAndVersions();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const execute = async () => {
      setBusy(true);
      setError(null);
      try {
        const apiName = "ThermonovaAPI";
        const path = templateId ? "/role/update" : "/role/add";
        await API.post(apiName, path, {
          body: {
            ...(templateId
              ? {
                  id: templateId,
                }
              : {}),
            ...data,
            roleType: "template",
          },
        });
        mutate("roleTemplates");
        setSuccess(true);
        handleClose();
      } catch (error) {
        const errorText = error.message || error;
        setError(errorText);
      } finally {
        setBusy(false);
      }
    };
    execute();
  };
  return (
    <>
      <Dialog open onClose={handleClose} maxWidth="lg">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <DialogTitle>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {templateId ? "Edit Rule" : "Create Rule"}
                <span
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  {isLoadingTemplate && <CircularProgress size={20} />}
                </span>
              </div>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Enter the name of the rule and a description.
              </DialogContentText>
              <TextField
                autoFocus
                label="Rule Name"
                required
                margin="dense"
                id="name"
                name="name"
                type="text"
                fullWidth
                variant="outlined"
                size="small"
                {...methods.register("name", { required: true })}
              />
              <TextField
                margin="dense"
                id="description"
                name="description"
                label="Description"
                type="text"
                fullWidth
                variant="outlined"
                size="small"
                multiline
                maxRows={4}
                {...methods.register("description", { required: false })}
              />
              <SoftwareModelAndVersion
                softwareModels={softwareModels}
                softwareVersions={softwareVersions}
                isLoading={isLoading}
                error={softwareError}
                activeSoftwareModel={activeSoftwareModel}
                setActiveSoftwareModel={setActiveSoftwareModel}
                activeSoftwareVersion={activeSoftwareVersion}
                setActiveSoftwareVersion={setActiveSoftwareVersion}
              />
              <SettingsTransferList
                rules={rules}
                activeSoftwareModel={activeSoftwareModel}
                activeSoftwareVersion={activeSoftwareVersion}
              />
              <FormControlLabel
                style={{
                  marginTop: "20px",
                }}
                control={
                  <Controller
                    name="defaultActive"
                    render={({ field }) => (
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                    defaultValue={false}
                    control={methods.control}
                  />
                }
                label="Default Active on Assigning"
              />
              {errorFetchingTemplate && (
                <Alert
                  severity="error"
                  style={{
                    marginTop: "20px",
                  }}
                >
                  {errorFetchingTemplate.message}
                </Alert>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" disabled={busy}>
                {templateId ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
      <Snackbar
        open={success}
        autoHideDuration={4000}
        onClose={() => {
          setSuccess(false);
        }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Role created successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!error}
        autoHideDuration={4000}
        onClose={() => {
          setError("");
        }}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
}

function SettingsTransferList(props) {
  const methods = useFormContext();

  const [checked, setChecked] = React.useState<readonly RuleOption[]>([]);
  const [left, setLeft] = React.useState<readonly RuleOption[]>([]);
  const [right, setRight] = React.useState<readonly RuleOption[]>([]);
  const { rules, activeSoftwareModel, activeSoftwareVersion } = props;

  React.useEffect(() => {
    if (rules) {
      setLeft(rules);
    }

    setRight([]);
    setChecked([]);
  }, [rules]);

  React.useEffect(() => {
    if (activeSoftwareVersion) {
      const values = methods.getValues();

      if (!values.rules) {
        return;
      }
      const { sfModelKey, sfVersionKey } = buildRuleKey(
        activeSoftwareModel,
        activeSoftwareVersion,
        ""
      );
      const rightIds = Object.keys(
        values.rules?.[sfModelKey]?.[sfVersionKey] || {}
      ).map((idWithPrefix) => idWithPrefix.replace("id_", ""));
      const rightRules = rules.filter((rule) => rightIds.includes(rule.value));
      setRight(rightRules);
      setLeft(not(rules, rightRules));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSoftwareModel, activeSoftwareVersion]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const handleToggle = (value: RuleOption) => () => {
    const currentIndex = checked.findIndex(
      (rule) => rule.value === value.value
    );
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: readonly RuleOption[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: readonly RuleOption[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));

    for (const rule of leftChecked) {
      methods.register(
        buildRuleKey(activeSoftwareModel, activeSoftwareVersion, rule.value)
          .key,
        {
          value: true,
        }
      );
    }
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));

    for (const rule of rightChecked) {
      methods.unregister(
        buildRuleKey(activeSoftwareModel, activeSoftwareVersion, rule.value).key
      );
    }
  };

  const customList = (
    title: React.ReactNode,
    items: readonly RuleOption[],
    showAbilityControl: boolean = false
  ) => (
    <Card
      sx={{
        overflow: "auto",
        mt: 2,
      }}
    >
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 500,
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {!(activeSoftwareModel && activeSoftwareVersion) && (
          <ListItemText
            style={{
              textAlign: "center",
            }}
            primary={
              <Alert
                severity="info"
                style={{
                  margin: "10px",
                }}
              >
                Please select a software model and version to view rules
              </Alert>
            }
          />
        )}
        {items.map((rule: RuleOption) => {
          const labelId = `transfer-list-all-item-${rule.value}-label`;

          return (
            <ListItemButton
              key={rule.value}
              role="listitem"
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                if ((e.target as HTMLElement).id === "rw-switch") {
                  return;
                }
                handleToggle(rule)();
              }}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.some((r) => r.value === rule.value)}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={rule.label}
                secondary={
                  showAbilityControl ? (
                    rule.ability === "rw" ? (
                      <FormControlLabel
                        control={
                          <Controller
                            name={`${
                              buildRuleKey(
                                activeSoftwareModel,
                                activeSoftwareVersion,
                                rule.value
                              ).key
                            }.rw`}
                            render={({ field }) => (
                              <Switch
                                id="rw-switch"
                                {...field}
                                checked={field.value}
                                size="small"
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                }
                              />
                            )}
                            defaultValue={false}
                            control={methods.control}
                          />
                        }
                        label="Read/Write"
                      />
                    ) : null
                  ) : (
                    <Chip
                      label={rule.ability === "rw" ? "Read/Write" : "Read Only"}
                      size="small"
                      color={rule.ability === "rw" ? "primary" : "default"}
                    />
                  )
                }
              />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList("Choices", left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList("Chosen", right, true)}</Grid>
    </Grid>
  );
}

function buildRuleKey(
  softwareModel: string,
  softwareVersion: string,
  ruleId: string
) {
  const sfModelKey = `sfModel_${softwareModel}`;
  const sfVersionKey = `sfVersion_${softwareVersion}`;
  const prefix = `rules.${sfModelKey}.${sfVersionKey}.`;
  return {
    key: `${prefix}id_${ruleId}`,
    prefix,
    sfModelKey,
    sfVersionKey,
  };
}
