import { Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Switch from "@mui/material/Switch";
import useSWR from "swr";
import { roleTemplatesFetcher } from "../util/fetcher";
import { Skeleton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useAuthorizer } from "../util/authorizer";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: theme.palette.text.primary,
  "&.Mui-selected": {
    color: theme.palette.primary.main,
  },
}));

const StyledSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const label = { inputProps: { "aria-label": "Permission Checkbox" } };

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function AccessRightsForm({
  orgPermissions,
}: {
  orgPermissions?: {
    [key: string]: boolean;
  };
}) {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        borderRadius: 2,
        overflow: "hidden",
        boxShadow: 3,
      }}
    >
      <StyledTabs
        value={activeTab}
        onChange={handleChange}
        aria-label="manage-permissions-tabs"
      >
        <StyledTab label="HP Rules" {...a11yProps(0)} />
        <StyledTab label="General Roles" {...a11yProps(1)} />
      </StyledTabs>
      <Box sx={{ p: 3 }}>
        {activeTab === 0 && <HeatpumpRules />}
        {activeTab === 1 && (
          <GeneralPermissions orgPermissions={orgPermissions} />
        )}
      </Box>
    </Box>
  );
}

function HeatpumpRules() {
  const { key, fetcher } = roleTemplatesFetcher();
  const { data, error, isLoading } = useSWR(key, fetcher);
  return (
    <>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          maxHeight: 400,
          overflow: "auto",
        }}
      >
        {isLoading &&
          Array.from(Array(5).keys()).map((index) => (
            <ListItem
              key={index}
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <Skeleton variant="text" width="100%" height={60} />
            </ListItem>
          ))}
        {!isLoading && data?.length === 0 && (
          <Alert severity="info" sx={{ mb: 2 }}>
            No role templates found
          </Alert>
        )}
        {data?.map((template, index) => (
          <ListItem
            key={template.sk}
            sx={{
              borderColor: "divider",
              border: "1px solid rgba(0, 0, 0, 0.12)",
              borderRadius: 2,
              "&:hover": { backgroundColor: "background.paper" },
              marginBottom: 1,
            }}
          >
            <ListItemText
              id={`switch-list-label-${template.name}`}
              primary={
                <Typography
                  variant="subtitle1"
                  color="primary"
                  fontWeight="medium"
                >
                  {template.name}
                </Typography>
              }
              secondary={
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mt: 0.5 }}
                >
                  {template.description}
                </Typography>
              }
            />
            <Controller
              render={({ field }) => (
                <StyledSwitch
                  {...field}
                  {...label}
                  checked={field.value}
                  inputProps={{
                    "aria-labelledby": `switch-list-label-${template.sk}`,
                  }}
                />
              )}
              name={`rules.${template.sk}`}
            />
          </ListItem>
        ))}
      </List>
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </>
  );
}

function GeneralPermissions({
  orgPermissions,
}: {
  orgPermissions?: {
    [key: string]: boolean;
  };
}) {
  const auth = useAuthorizer();
  const orgHasHPUpdatePermissions =
    auth.isAdministrator || orgPermissions?.heatpump_update;

  return (
    <>
      <Grid item xs={6} md={8}>
        {!orgHasHPUpdatePermissions && (
          <Alert severity="info" sx={{ mb: 2 }}>
            Organization does not have permissions to set general roles
          </Alert>
        )}
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            maxHeight: 400,
            overflow: "auto",
          }}
        >
          {[
            ...(orgHasHPUpdatePermissions
              ? [
                  {
                    value: "heatpump_update",
                    label: "Heat pump Update",
                    description: "Can update heat pump information",
                  },
                ]
              : []),
          ]?.map((hp) => {
            const labelId = `checkbox-list-label-${hp.value}`;
            return (
              <ListItem
                key={hp.value}
                disablePadding
                sx={{
                  borderColor: "divider",
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                  borderRadius: 2,
                  "&:hover": { backgroundColor: "background.paper" },
                  marginBottom: 1,
                }}
              >
                <Controller
                  name={`permissions.${hp.value}`}
                  render={({ field }) => (
                    <>
                      <ListItemButton
                        role={undefined}
                        onClick={() => {
                          field.onChange({
                            target: {
                              value: !Boolean(field.value),
                            },
                          });
                        }}
                        dense
                      >
                        <ListItemText
                          id={labelId}
                          primary={
                            <span>
                              <Typography variant="subtitle1" color="primary">
                                {hp.label}
                              </Typography>
                            </span>
                          }
                          secondary={
                            <Typography variant="body2" color="text.secondary">
                              {hp.description}
                            </Typography>
                          }
                        />
                        <ListItemIcon>
                          <StyledSwitch
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            {...label}
                            checked={Boolean(field.value)}
                            inputProps={{ "aria-labelledby": labelId }}
                            {...field}
                          />
                        </ListItemIcon>
                      </ListItemButton>
                    </>
                  )}
                />
              </ListItem>
            );
          })}
        </List>
      </Grid>
    </>
  );
}
