import * as React from "react";
import Paper from "@mui/material/Paper";
import FormDialog from "./ConnectGW/HpControlConnectGWDialog";
import AddHPView from "./AddHeatpump/AddHeatpumpView";
import EditHPView from "./EditHeatpump/EdditHeatpumpView";
import EditCLView from "./EditContactList/EditContactListView";

import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { HPControlLogic } from "./HPControlLogic";
import { useAuthorizer } from "../util/authorizer";

export default function BasicTable(props: any) {
  const {
    heatpumps,
    heatpumpsFilterText,
    columns,
    open,
    handleClose,
    dialogSerialnumber,
    setDialogSerialnumber,
    fcUpdateHPData,
    QuickSearchToolbar,
    requestSearch,
    openAddHeatpumpModal,
    openAddHPModal,
    closeAddHeatpumpModal,
    openEditHPModal,
    closeEditHeatpumpModal,
    editHPData,
    openEditContactListModal,
    closeEditCLModal,
  } = HPControlLogic();

  const { can } = useAuthorizer();

  return (
    <div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Box sx={{ height: props.height, width: 1 }}>
          <DataGrid
            disableSelectionOnClick
            components={{ Toolbar: QuickSearchToolbar }}
            rows={heatpumps}
            columns={columns}
            initialState={{
              sorting: {
                sortModel: [{ field: "alarm", sort: "desc" }],
              },
            }}
            componentsProps={{
              toolbar: {
                value: heatpumpsFilterText,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  requestSearch(event.target.value);
                },
                clearSearch: () => requestSearch(""),
              },
            }}
          />
        </Box>
        <FormDialog
          open={open}
          handleClose={handleClose}
          serialnumber={dialogSerialnumber}
          setSerialnumber={setDialogSerialnumber}
          updateHPdata={fcUpdateHPData}
        />
        <AddHPView
          open={openAddHPModal}
          handleClose={closeAddHeatpumpModal}
          updateHPdata={fcUpdateHPData}
        />
        <EditHPView
          open={openEditHPModal}
          handleClose={closeEditHeatpumpModal}
          updateHPdata={fcUpdateHPData}
          HPdata={editHPData}
        />
        <EditCLView
          open={openEditContactListModal}
          handleClose={closeEditCLModal}
          updateHPdata={fcUpdateHPData}
          HPdata={editHPData}
        />
      </Paper>
      {can({ action: "create", subject: "HP" }) && (
        <Tooltip title="Add Heatpump" placement="top">
          <IconButton
            sx={{ position: "absolute", bottom: 45, right: 45 }}
            onClick={openAddHeatpumpModal}
          >
            <AddCircleOutlineIcon color="primary" style={{ fontSize: 40 }} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}
