import { ReactNode, useMemo } from "react";
import { FC, createContext } from "react";
import { PureAbility, createMongoAbility } from "@casl/ability";
import useSWR from "swr";
import { API } from "aws-amplify";
import { Actions, AppAbility, RulesType, Subjects } from "./PolicyTypes";
interface CanInput {
  subject: Subjects;
  action: Actions;
}
interface AuthorizerContextType {
  can: (input: CanInput) => boolean;
  isOrgAdmin: () => boolean;
  userId: string;
  isAdministrator: boolean;
  organizationId: string;
  inOrgPermissions: {
    hasHPUpdatePermissions: boolean;
    canViewCustomLogs: boolean;
  };
}

export type RoleGrant = {
  action: string;
  subject: string;
  conditions?: any;
};

export const AuthorizerContext = createContext<AuthorizerContextType | null>(
  null
);

interface AuthorizerProviderProps {
  children: ReactNode;
}

const getUserData = () => {
  const apiName = "ThermonovaAPI";
  const path = "/users/me";
  return API.get(apiName, path, {});
};

export const AuthorizerProvider: FC<AuthorizerProviderProps> = (props) => {
  const { children } = props;

  const { data, error, isLoading } = useSWR("getUserData", getUserData);
  const ability = useMemo(() => {
    if (isLoading || error || !data?.policies) {
      return new PureAbility([]);
    }
    const createAbility = (rules: RulesType) =>
      createMongoAbility<AppAbility>(rules, {
        detectSubjectType: (object) => object.__typename,
      });
    console.log({
      policies: data.policies,
    });
    const ability = createAbility(data.policies);
    return ability;
  }, [data, error, isLoading]);

  const can = ({ action, subject }: CanInput): boolean => {
    return ability.can(action, subject);
  };

  const isOrgAdmin = () => {
    return data?.roleInOrganization === "admin";
  };

  const inOrgPermissions = useMemo(() => {
    const denyAll = {
      hasHPUpdatePermissions: false,
      canViewCustomLogs: false,
    };
    const allowAll = {
      hasHPUpdatePermissions: true,
      canViewCustomLogs: true,
    };
    if (!data) {
      return denyAll;
    }
    if (data?.isAdministrator) {
      return allowAll;
    }
    if (!data?.organizationId) {
      return denyAll;
    }
    const currentOrganization = data["userOrganization"];
    const orgPermissions = currentOrganization?.permissions || {};
    const hasHPUpdatePermissions =
      data.isAdministrator || orgPermissions?.heatpump_update;

    return {
      hasHPUpdatePermissions,
      canViewCustomLogs: data?.isAdministrator,
    };
  }, [data]);

  return (
    <AuthorizerContext.Provider
      value={{
        can,
        isOrgAdmin,
        userId: data?.userId,
        isAdministrator: data?.isAdministrator,
        organizationId: data?.organizationId,
        inOrgPermissions,
      }}
    >
      {children}
    </AuthorizerContext.Provider>
  );
};
