import React, { useState } from "react";

import SettingsView from "../Settings/SettingsView";
import HotspotsView from "../Hotspots/HotspotsView";
import Overview from "../Overview/Overview";
import SoftwareUpload from "../SoftwareUpload/SoftwareUpload";
import ChartView from "../Charts/chartView";

export default function NavigationLogic(HPData, webWorker, context) {
  const [HPName] = useState(HPData.Name);
  const [contentSize, setContentSize] = useState("lg");

  const [mainView, setMainView] = useState("");
  const [selectedMenu, setSelectedMenu] = useState("");
  const [headerTitle, setTitle] = useState("");

  const setHeaderTitle = (data: any) => {
    const t = HPName + " - " + data;
    setTitle(t);
  };

  React.useEffect(() => {
    setTitle(HPName + " - Overview");
    setMainView("Overview");
  }, [HPName]);

  const contentView = (
    <div>
      {mainView === "Overview" && (
        <Overview
          setSelectedMenu={setSelectedMenu}
          setTitle={setTitle}
          setMainView={setMainView}
        />
      )}

      {mainView === "Settings Master" && (
        <SettingsView Settings="master" context={context} />
      )}
      {mainView === "Settings Modul 1" && (
        <SettingsView Settings="modul1" context={context} />
      )}
      {mainView === "Settings Modul 2" && (
        <SettingsView Settings="modul2" context={context} />
      )}
      {mainView === "Settings Modul 3" && (
        <SettingsView Settings="modul3" context={context} />
      )}
      {mainView === "Settings Modul 4" && (
        <SettingsView Settings="modul4" context={context} />
      )}
      {mainView === "Hotspots Master" && (
        <HotspotsView Hotspots="master" context={context} />
      )}
      {mainView === "Hotspots Modul 1" && (
        <HotspotsView Hotspots="modul1" context={context} />
      )}
      {mainView === "Hotspots Modul 2" && (
        <HotspotsView Hotspots="modul2" context={context} />
      )}
      {mainView === "Hotspots Modul 3" && (
        <HotspotsView Hotspots="modul3" context={context} />
      )}
      {mainView === "Hotspots Modul 4" && (
        <HotspotsView Hotspots="modul4" context={context} />
      )}
      {mainView === "Graph" && (
        <ChartView webWorker={webWorker} context={context} />
      )}
      {mainView === "Upload Software" && <SoftwareUpload />}
    </div>
  );

  const navClickHandler = (e: string) => {
    setSelectedMenu(e);

    switch (e) {
      case "Overview":
        setHeaderTitle("Overview");
        setContentSize("lg");

        setMainView("Overview");
        break;
      case "S_Master":
        setHeaderTitle("Settings Master");
        setContentSize("lg");
        setMainView("Settings Master");
        break;
      case "S_Modul 1":
        setHeaderTitle("Settings Modul 1");
        setMainView("Settings Modul 1");
        break;
      case "S_Modul 2":
        setHeaderTitle("Settings Modul 2");
        setContentSize("lg");
        setMainView("Settings Modul 2");
        break;
      case "S_Modul 3":
        setHeaderTitle("Settings Modul 3");
        setContentSize("lg");
        setMainView("Settings Modul 3");
        break;
      case "S_Modul 4":
        setHeaderTitle("Settings Modul 4");
        setContentSize("lg");
        setMainView("Settings Modul 4");
        break;
      case "H_Master":
        setMainView("Hotspots Master");
        setHeaderTitle("Hotspots Master");
        setContentSize("lg");
        break;
      case "H_Modul 1":
        setMainView("Hotspots Modul 1");
        setHeaderTitle("Hotspots Modul 1");
        setContentSize("lg");
        break;
      case "H_Modul 2":
        setMainView("Hotspots Modul 2");
        setHeaderTitle("Hotspots Modul 2");
        setContentSize("lg");
        break;
      case "H_Modul 3":
        setMainView("Hotspots Modul 3");
        setHeaderTitle("Hotspots Modul 3");
        setContentSize("lg");
        break;
      case "H_Modul 4":
        setMainView("Hotspots Modul 4");
        setHeaderTitle("Hotspots Modul 4");
        setContentSize("lg");
        break;
      case "History":
        setMainView("History");
        setHeaderTitle("History");
        setContentSize("lg");
        break;
      case "Graph":
        setMainView("Graph");
        setHeaderTitle("Graph");
        setContentSize("xl");
        break;
      case "Upload Software":
        setMainView("Upload Software");
        setHeaderTitle("Upload Software");
        setContentSize("lg");
        break;
      case "Loading":
        setMainView("Loading");
        setHeaderTitle("Loading");
        setContentSize("lg");
        break;
      default:
        setMainView("Overview");
        setHeaderTitle("Overview");
        setContentSize("lg");
        break;
    }
  };

  return {
    headerTitle,
    navClickHandler,
    contentSize,
    contentView,
    selectedMenu,
  };
}
