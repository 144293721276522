import React, { useRef } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";

import { PubSub } from "aws-amplify";

import { useSelector, useDispatch } from "react-redux";
import { setChangeSettingsState } from "../Redux/HP";
import { canAccessSettingsCell } from "../../util/getRulesForModel";

export default function SettingsLogic(props) {
  const modul = props.Settings;
  const model = props.Model;

  const {
    masterSettings,
    modul1Settings,
    modul2Settings,
    modul3Settings,
    modul4Settings,
    handlerTopic,
    changeSettingsState,
    CognitoUsername,
  } = useSelector((state) => state.heatpump);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogLabel, setDialogLabel] = React.useState("");
  const [changedValue, setChangedValue] = React.useState(null);
  const [parameterData, setParameterData] = React.useState({});
  const [dialogInputField, setDialogInputField] = React.useState("text");
  const [dialogSelectItems, setDialogSelectitems] = React.useState([]);
  const [sendButtonDisabled, setSendButtonDisabled] = React.useState(true);
  var controllerID = useRef();
  var Settings = useRef();

  const setControllerID = (e) => {
    controllerID.current = e;
  };
  const setSettings = (e) => {
    Settings.current = e;
  };

  switch (model) {
    case "Nature Energy":
      //console.log("Nature")
      switch (modul) {
        case "master":
          //console.log(masterSettings)
          setSettings(masterSettings);
          setControllerID(1);
          break;
        case "modul1":
          setSettings(modul1Settings);
          setControllerID(2);
          break;
        case "modul2":
          setSettings(modul2Settings);
          setControllerID(3);
          break;
        case "modul3":
          setSettings(modul3Settings);
          setControllerID(4);
          break;
        case "modul4":
          setSettings(modul4Settings);
          setControllerID(5);
          break;

        default:
          break;
      }
      break;
    case "GeoNova 26":
      //console.log("Nature")
      switch (modul) {
        case "master":
          //console.log(masterSettings)
          setSettings(masterSettings);
          setControllerID(1);
          break;
        case "modul1":
          setSettings(modul1Settings);
          setControllerID(2);
          break;
        case "modul2":
          setSettings(modul2Settings);
          setControllerID(3);
          break;
        case "modul3":
          setSettings(modul3Settings);
          setControllerID(4);
          break;
        case "modul4":
          setSettings(modul4Settings);
          setControllerID(5);
          break;

        default:
          break;
      }
      break;
    default:
      //console.log("default")
      switch (modul) {
        case "master":
          //console.log(masterSettings)
          setSettings(masterSettings);
          setControllerID(100);
          break;
        case "modul1":
          setSettings(modul1Settings);
          setControllerID(1);
          break;
        case "modul2":
          setSettings(modul2Settings);
          setControllerID(1);
          break;
        case "modul3":
          setSettings(modul3Settings);
          setControllerID(2);
          break;
        case "modul4":
          setSettings(modul4Settings);
          setControllerID(2);
          break;

        default:
          break;
      }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDialogHeader("");
    setChangedValue(0);
    setDialogLabel("");
    setDialogInputField("text");
    setDialogSelectitems([]);
    setSendButtonDisabled(true);
  };
  const countDecimals = (value) => {
    var char_array = String(value).split(""); // split every single char
    var not_decimal = char_array.lastIndexOf(".");
    return not_decimal < 0 ? 0 : char_array.length - not_decimal - 1;
  };

  const handleChangeValueInput = (e) => {
    let out = e.target.value;
    if (out !== "") {
      setSendButtonDisabled(false);
    } else {
      setSendButtonDisabled(true);
    }
    setChangedValue(e.target.value);
  };

  const handleChangeValueInputDecimals = (e) => {
    if (e.target.type === "text") {
      let out;
      let valueOrMinMax;
      if (
        parseFloat(changedValue) >
        calculateDecimalData(parameterData.col5.Dec, parameterData.col5.Max)
      ) {
        valueOrMinMax = calculateDecimalData(
          parameterData.col5.Dec,
          parameterData.col5.Max
        );
      } else if (
        parseFloat(changedValue) <
        calculateDecimalData(parameterData.col5.Dec, parameterData.col5.Min)
      ) {
        valueOrMinMax = calculateDecimalData(
          parameterData.col5.Dec,
          parameterData.col5.Min
        );
      } else {
        valueOrMinMax = changedValue.replace(",", ".");
      }

      let numberDecimals = countDecimals(valueOrMinMax);
      let val = parseInt(parameterData.col5.Dec) - numberDecimals;
      switch (val) {
        case -2:
          out = valueOrMinMax.slice(0, -2);
          break;
        case -1:
          out = valueOrMinMax.slice(0, -1);
          break;
        case 0:
          out = valueOrMinMax;
          break;
        case 1:
          out = valueOrMinMax + ".0";
          break;
        case 2:
          out = valueOrMinMax + ".00";
          break;
        default:
          break;
      }

      setChangedValue(out);
    }
  };

  const handleChangeValue = (e) => {
    setParameterData(e);
    setDialogHeader(e.col2);
    // Debug here
    console.log({
      e: e,
    });
    //
    setDialogLabel(
      "Min: " +
        calculateDecimalData(e.col5.Dec, e.col5.Min) +
        " Max: " +
        calculateDecimalData(e.col5.Dec, e.col5.Max)
    );
    setChangedValue(calculateDecimalData(e.col5.Dec, e.col4));
    if (Object.keys(e.col5.enum).length === 0) {
      setDialogInputField("text");
    } else if (Object.keys(e.col5.enum).length === 1) {
      setDialogInputField("button");
      console.log(Object.keys(e.col5.enum)[0]);
      setChangedValue(Object.keys(e.col5.enum)[0]);
      setSendButtonDisabled(false);
    } else {
      setDialogInputField("select");
      for (const [key] of Object.entries(e.col5.enum)) {
        setDialogSelectitems((oldArray) => [
          ...oldArray,
          <MenuItem key={key} value={String(key)}>
            {e.col5.enum[key]}
          </MenuItem>,
        ]);
      }
    }

    handleClickOpen();
  };

  const getDialogAlert = () => {
    let out;
    switch (changeSettingsState["DataState"]) {
      case 1:
        out = (
          <Alert severity="success">{changeSettingsState["DataMessage"]}</Alert>
        );
        break;
      case 2:
        out = (
          <Alert severity="info">{changeSettingsState["DataMessage"]}</Alert>
        );
        break;
      case 3:
        out = (
          <Alert severity="error">{changeSettingsState["DataMessage"]}</Alert>
        );
        break;

      default:
        out = "";
        break;
    }

    return out;
  };

  const getChangeValueInput = () => {
    let out;
    switch (dialogInputField) {
      case "select":
        out = (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={changedValue}
            fullWidth
            onChange={handleChangeValueInput}
          >
            {dialogSelectItems}
          </Select>
        );
        break;
      case "button":
        out = "";
        break;
      case "text":
        out = (
          <TextField
            autoFocus
            margin="dense"
            id="changeValue"
            label={dialogLabel}
            value={changedValue}
            type="text"
            fullWidth
            variant="standard"
            onChange={handleChangeValueInput}
            onBlur={handleChangeValueInputDecimals}
            onKeyPress={(event) => {
              if (!/[0-9,.-]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        );
        break;
      default:
        break;
    }

    return out;
  };

  const getActionButtons = () => {
    if (dialogInputField === "button") {
      return (
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={sendButtonDisabled} onClick={sendData}>
            {dialogHeader}
          </Button>
        </DialogActions>
      );
    } else {
      return (
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={sendButtonDisabled} onClick={sendData}>
            Change
          </Button>
        </DialogActions>
      );
    }
  };

  const sendData = () => {
    let out = changedValue?.replace(".", "");
    //convert data
    //dataChange({"userID": userID, "ID": controllerID.current, "ModbusAdr": parameterData.col1, "value": parseInt(out)});
    //console.log({"change": {"userID": CognitoUsername, "ID": controllerID.current, "ModbusAdr": parameterData.col1, "value": parseInt(out)}})
    PubSub.publish(handlerTopic, {
      change: {
        userID: CognitoUsername,
        ID: controllerID.current,
        ModbusAdr: parameterData.col1,
        value: parseInt(out),
      },
    });
    dispatch(
      setChangeSettingsState({ DataMessage: "Changing value", DataState: 2 })
    );
  };

  const calculateDecimalData = (dec, value) => {
    if (value === "") {
      return "";
    } else {
      switch (dec) {
        case "0":
          return parseFloat(String(parseInt(value))).toFixed(parseInt(dec));
        case "1":
          return parseFloat(String(parseInt(value) / 10)).toFixed(
            parseInt(dec)
          );
        case "2":
          return parseFloat(String(parseInt(value) / 100)).toFixed(
            parseInt(dec)
          );
        case "3":
          return parseFloat(String(parseInt(value) / 1000)).toFixed(
            parseInt(dec)
          );
        case "4":
          return parseFloat(String(parseInt(value) / 10000)).toFixed(
            parseInt(dec)
          );

        default:
          return String(parseInt(value));
      }
    }
  };

  const getCells = ({ rules } = {}) => {
    let out = Settings?.current
      ?.filter((row) => {
        if (rules === undefined) {
          return true;
        }
        return canAccessSettingsCell(rules, row, "r");
      })
      ?.map((row) => {
        let value;
        let defaultValue;
        let actions;
        const canChangeSttings = canAccessSettingsCell(rules, row, "rw");
        if (Object.keys(row.col5.enum).length !== 0) {
          value = (
            <TableCell align="right">{row.col5.enum[row.col4]}</TableCell>
          );
          defaultValue = (
            <TableCell align="right">{row.col5.enum[row.col3]}</TableCell>
          );

          actions = (
            <IconButton
              color="primary"
              aria-label="change setting"
              onClick={() => {
                handleChangeValue(row);
              }}
              disabled={!canChangeSttings}
            >
              <EditIcon />
            </IconButton>
          );
        } else {
          value = (
            <TableCell align="right">
              {calculateDecimalData(row.col5.Dec, row.col4) +
                " " +
                row.col5.Unit}
            </TableCell>
          );
          defaultValue = (
            <TableCell align="right">
              {calculateDecimalData(row.col5.Dec, row.col3) +
                " " +
                row.col5.Unit}
            </TableCell>
          );

          actions = (
            <IconButton
              color="primary"
              aria-label="change setting"
              onClick={() => {
                handleChangeValue(row);
              }}
              disabled={!canChangeSttings}
            >
              <EditIcon />
            </IconButton>
          );
        }

        return (
          <TableRow
            key={row.id}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row" align="left">
              {row.col1}
            </TableCell>
            <TableCell>{row.col2}</TableCell>
            {defaultValue}
            {value}
            <TableCell align="right">{actions}</TableCell>
          </TableRow>
        );
      });

    return out;
  };

  return {
    getCells,
    open,
    handleClose,
    dialogHeader,
    getChangeValueInput,
    sendData,
    getDialogAlert,
    sendButtonDisabled,
    getActionButtons,
  };
}
