import React from "react";
import { API } from "aws-amplify";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";

export const ConnectGWDialogLogic = (
  handleClose: () => void,
  serialnumber: string,
  setSerialnumber: (str: string) => void,
  updateHPdata: any,
  open: boolean
) => {
  const [gwID, setGwID] = React.useState("");
  const [APIalarm, setAPIalarm] = React.useState(0);
  const [gatewayIDs, setGatewayIDs] = React.useState<any[]>();
  const handleSend = () => {
    postGWID()
      .then((response) => {
        handleAPIResponse(response);
      })
      .catch((error) => {
        console.log(error.response);
        handleAPIResponse("error");
      });
  };

  React.useEffect(() => {
    getGatewayIDs()
      .then((response) => {
        var out: any[] = [];
        response?.forEach((element: any) => {
          if (element.serialnumber === "") {
            out.push(element);
          }
        });
        const listItems: any[] = out.map((gatewayID) => (
          <MenuItem key={gatewayID.gatewayID} value={gatewayID.gatewayID}>
            {gatewayID.gatewayID}
          </MenuItem>
        ));
        setGatewayIDs(listItems);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, [open]);

  const handleAPIResponse = (e: any) => {
    if (e.gw === "connect gateway") {
      setAPIalarm(1);
      setSerialnumber("");
      setGwID("");
      updateHPdata();
      delay(1000).then(() => {
        handleClose();
        setAPIalarm(0);
      });
    } else if (e.gw === "is connected") {
      setAPIalarm(2);
    } else if (e.gw === "does not exist") {
      setAPIalarm(3);
    } else if (e === "error") {
      setAPIalarm(4);
    }
  };

  const handleGWidChange = (e: any) => {
    setGwID(e.target.value);
  };

  async function postGWID() {
    const apiName = "ThermonovaAPI";
    const path = "/HP/gatewayid";
    const myInit = {
      // OPTIONAL
      body: {
        serialnumber: serialnumber,
        gatewayID: gwID,
      }, // replace this with attributes you need
    };

    return await API.post(apiName, path, myInit);
  }

  async function getGatewayIDs() {
    const apiName = "ThermonovaAPI";
    const path = "/HP/gatewayid";
    const myInit = {
      // OPTIONAL
      queryStringParameters: {
        // OPTIONAL
      },
    };

    return await API.get(apiName, path, myInit);
  }

  function delay(time: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const returnAlert = () => {
    switch (APIalarm) {
      case 1:
        return (
          <Alert severity="success">Gateway is connected to heatpump.</Alert>
        );
      case 2:
        return (
          <Alert severity="warning">
            Gateway is already connected to another heatpump.
          </Alert>
        );
      case 3:
        return <Alert severity="error">Gateway does not exist</Alert>;
      case 4:
        return <Alert severity="error">An error occurred.</Alert>;
      default:
        return "";
    }
  };

  return { handleSend, gwID, handleGWidChange, returnAlert, gatewayIDs };
};
