const mapSettingPropToControllerIdx = (model, setting) => {
  switch (model) {
    case "Nova 110":
      return {
        master: "100",
        modul1: "1",
      }[setting];
    case "Nova 220":
      return {
        master: "100",
        modul1: "1",
      }[setting];
    case "Nova 330":
      return {
        master: "100",
        modul1: "1",
        modul2: "2",
      }[setting];
    case "Nova 440":
      return {
        master: "100",
        modul1: "1",
        modul2: "2",
      }[setting];
    case "Nature Energy":
      return {
        master: "1",
        modul1: "2",
        modul2: "3",
        modul3: "4",
        modul4: "5",
      }[setting];
  }
};

export type RulesMap = Record<
  string,
  {
    rw: boolean;
  }
>;

export const getRulesForModel = (hpData, settingsKey, allRules) => {
  if (!hpData) {
    return null;
  }

  if (!hpData.Model) {
    return null;
  }

  if (allRules?.["*"]) {
    return {
      "*": {
        rw: true,
      },
    };
  }

  if (!hpData.Controller) {
    return null;
  }

  const rulesKey = mapSettingPropToControllerIdx(hpData.Model, settingsKey);

  if (!rulesKey) {
    return null;
  }

  const Controller = hpData.Controller;
  const model = Controller[rulesKey]["SoftwareModel"];
  const rules = allRules?.[`sfModel_${model}`] || null;
  if (!rules) {
    return null;
  }
  if (typeof rules !== "object") {
    return null;
  }
  if (Object.keys(rules).length === 0) {
    return null;
  }
  return rules as RulesMap;
};

export const canAccessSettingsCell = (
  modelRules: RulesMap,
  cell,
  action: "r" | "rw"
) => {
  if (!modelRules) {
    return false;
  }

  if (modelRules["*"]) {
    return true;
  }

  if (!cell) {
    return false;
  }

  const ruleId = `id_${cell.id}`;

  if (action === "r") {
    return modelRules[ruleId];
  }

  if (action === "rw") {
    return modelRules[ruleId] && modelRules[ruleId].rw;
  }
};
