import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import Tooltip from "@mui/material/Tooltip";
import NetworkCellIcon from "@mui/icons-material/NetworkCell";
import SignalCellularConnectedNoInternet0BarIcon from "@mui/icons-material/SignalCellularConnectedNoInternet0Bar";

import SimCardIcon from "@mui/icons-material/SimCard";
import CableIcon from "@mui/icons-material/Cable";
import { useDispatch, useSelector } from "react-redux";

import { setHeatpumpsFilterText } from "../Redux/MainStore";
import { ReportProblem } from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";
import { useAuthorizer } from "../util/authorizer";

export const HPControlLogic = () => {
  const Dispatch = useDispatch();
  const heatpumps = useSelector(
    (state: any) => state.mainData.filteredHeatpumps
  );
  const heatpumpsFilterText = useSelector(
    (state: any) => state.mainData.heatpumpsFilterText
  );
  const authorizer = useAuthorizer();

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (e: any) => {
    setOpen(true);
    setDialogSerialnumber(e.target.value);
  };
  const [dialogSerialnumber, setDialogSerialnumber] = React.useState();
  const handleClose = () => {
    setOpen(false);
  };

  const [openAddHPModal, setOpenAddHPModal] = React.useState(false);

  const openAddHeatpumpModal = () => {
    setOpenAddHPModal(true);
  };
  const closeAddHeatpumpModal = () => {
    setOpenAddHPModal(false);
  };

  const [openEditHPModal, setOpenEditHPModal] = React.useState(false);
  const [editHPData, setEditHPData] = React.useState({});
  const [openEditContactListModal, setOpenEditContactListModal] =
    React.useState(false);

  const openEditHeatpumpModal = () => {
    setOpenEditHPModal(true);
  };
  const closeEditHeatpumpModal = () => {
    setOpenEditHPModal(false);
    setEditHPData({});
  };

  const handleOpenEditHP = (e: React.MouseEvent<HTMLButtonElement>) => {
    heatpumps.forEach((element) => {
      if (element.id === (e.target as HTMLButtonElement).value) {
        setEditHPData(element);
      }
    });
    openEditHeatpumpModal();
  };

  const handleOpenContactModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setEditHPData(e);
    openEditCLModal();
  };

  const openEditCLModal = () => {
    setOpenEditContactListModal(true);
  };
  const closeEditCLModal = () => {
    setOpenEditContactListModal(false);
    setEditHPData({});
  };

  const fcUpdateHPData = () => {
    // setUpdateHPData((s) => !s);
    //console.log(!updateHPData)
  };

  const columns: GridColDef[] = [
    {
      field: "col20",
      headerName: "Connection",
      width: 85,
      renderCell: (cellValues) => {
        var ts = Date.now() - 900000;
        var onlineTs = Date.parse(cellValues.row.col13);
        var iconColor: "primary" | "success" | "disabled" = "primary";
        var onlineState = "";
        if (onlineTs >= ts) {
          iconColor = "success";
          onlineState = "Online";
        } else {
          iconColor = "disabled";
          onlineState = "Offline";
        }
        if (
          cellValues.row.col14 === "No Modem" ||
          cellValues.row.col14 === ""
        ) {
          return (
            <Tooltip title={"Ethernet - " + onlineState}>
              <IconButton>
                {onlineState === "Online" ? (
                  <CableIcon color={iconColor} />
                ) : (
                  <CableIcon color="disabled" />
                )}
              </IconButton>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={"4G Modem - " + onlineState}>
              <IconButton>
                {onlineState === "Online" ? (
                  <NetworkCellIcon color={iconColor} />
                ) : (
                  <SignalCellularConnectedNoInternet0BarIcon />
                )}
              </IconButton>
            </Tooltip>
          );
        }
      },
    },
    {
      field: "alarm",
      headerName: "Alarm",
      width: 80,
      renderCell: (cellValues) => {
        const state = cellValues.row.alarm === "true" ? "Error" : "No alarm";
        const iconColor = cellValues.row.alarm === "true" ? "error" : "success";
        return (
          <Tooltip title={state}>
            <IconButton>
              <ReportProblem color={iconColor} />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: "col14",
      headerName: "Simcard",
      width: 70,
      renderCell: (cellValues) => {
        let icon;
        let ToolTiptext;
        if (cellValues.row.col14 === "No Modem") {
          ToolTiptext = cellValues.row.col14;
          icon = <SimCardIcon color="disabled" />;
        } else if (cellValues.row.col14 === "") {
          ToolTiptext = "No Simcard";
          icon = <SimCardIcon color="primary" />;
        } else {
          ToolTiptext = cellValues.row.col14;
          icon = <SimCardIcon color="success" />;
        }

        return (
          <Tooltip title={ToolTiptext}>
            <IconButton>{icon}</IconButton>
          </Tooltip>
        );
      },
    },
    { field: "col1", headerName: "Heatpump", width: 260 },
    { field: "col2", headerName: "Model", width: 100 },
    { field: "col3", headerName: "Serialnumber", width: 80 },
    { field: "col7", headerName: "Gateway ID", width: 90 },
    {
      field: "col12",
      headerName: "Actions",
      width: 440,
      align: "right",
      renderCell: (cellValues) => {
        const hasActionAccess =
          authorizer.isAdministrator ||
          authorizer.can({ action: "manage", subject: "HP" });
        if (cellValues.row.col7 === "") {
          return (
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              {hasActionAccess && (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={(event) => {
                    handleOpenContactModal(cellValues.row);
                  }}
                >
                  Contact
                </Button>
              )}
              {hasActionAccess && (
                <Button
                  size="small"
                  variant="outlined"
                  value={cellValues.row.col3}
                  onClick={(event) => {
                    handleOpenEditHP(event);
                  }}
                >
                  Edit
                </Button>
              )}
              {hasActionAccess && (
                <Button
                  size="small"
                  variant="contained"
                  value={cellValues.row.col3}
                  onClick={(event) => {
                    handleClickOpen(event);
                  }}
                >
                  Connect Gateway
                </Button>
              )}
            </Stack>
          );
        } else {
          return (
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              {hasActionAccess && (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={(event) => {
                    handleOpenContactModal(cellValues.row);
                  }}
                >
                  Contact
                </Button>
              )}
              {hasActionAccess && (
                <Button
                  size="small"
                  variant="outlined"
                  value={cellValues.row.col3}
                  onClick={(event) => {
                    handleOpenEditHP(event);
                  }}
                >
                  Edit
                </Button>
              )}
              <Button
                size="small"
                variant="contained"
                value={cellValues.row.col3}
                onClick={(event) => {
                  openControlSite(event);
                }}
              >
                Control
              </Button>
            </Stack>
          );
        }
      },
    },
  ];

  const requestSearch = (searchValue: string) => {
    Dispatch(setHeatpumpsFilterText(searchValue));
    //setSearchText(searchValue);
  };

  type QuickSearchToolbarProps = {
    value: string;
    onChange: () => void;
    clearSearch: () => void;
  };

  function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <TextField
          autoFocus
          variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder="Search…"
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? "visible" : "hidden" }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          sx={{
            width: {
              xs: 1,
              sm: "auto",
            },
            m: (theme) => theme.spacing(1, 0.5, 1.5),
            "& .MuiSvgIcon-root": {
              mr: 0.5,
            },
            "& .MuiInput-underline:before": {
              borderBottom: 1,
              borderColor: "divider",
            },
          }}
        />
      </Box>
    );
  }
  QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  };

  function openControlSite(e: React.MouseEvent<HTMLButtonElement>) {
    //console.log(e)
    const serialnumber = (e.target as HTMLButtonElement).value;
    window.open("/HP?serialnumber=" + serialnumber);
  }

  return {
    heatpumps,
    heatpumpsFilterText,
    columns,
    open,
    handleClose,
    dialogSerialnumber,
    setDialogSerialnumber,
    fcUpdateHPData,
    QuickSearchToolbar,
    requestSearch,
    openAddHeatpumpModal,
    openAddHPModal,
    closeAddHeatpumpModal,
    openEditHPModal,
    openEditHeatpumpModal,
    closeEditHeatpumpModal,
    editHPData,
    openEditContactListModal,
    openEditCLModal,
    closeEditCLModal,
  };
};
